<template>
  <div class="sign" ref="sign">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">福利签到</div>
      <div class="rightBtn" @click="$router.push('/signRule')">规则</div>
      <!--            <div class="rightBtn" @click="publish">发布</div>-->
    </div>
    <div class="content">
      <div class="headInfo">
        <div class="headLeft">
          <div class="infoBox">
            <img class="icon" src="@/assets/png/goldIcon.png" />
            <span class="amount"> {{ walletInfo.point || 0 }}</span>
          </div>
          <div class="btnBox" @click="scrollBottom">
            <span>兑换会员</span>
            <svg-icon class="icon" icon-class="rightIcon"></svg-icon>
          </div>
        </div>

        <div class="headRight">
          <!--                    <img class="icon" src="@/assets/png/giftIcon.png" />-->
        </div>
      </div>

      <!-- 签到 -->
      <!-- <div class="signInList" v-if="signList.length > 0">
     <div class="signInDay">
       每日签到 <span>已连续签到 {{ signNum || 0 }} 天</span>
     </div>
     <div class="trophy"></div>
     <div class="dayList">
       <div
         class="dayItem"
         :class="{ bigDayItem: i >= 6, dayItem1: item.status != 1 }"
         v-for="(item, i) in signList"
         :key="i"
       >
         <div class="dayNum">
           {{ "第" + `${item.finishCondition}` + "天" }}
         </div>
         <div
           v-if="item.prizes.length > 0"
           :class="`day ${getClass(item.prizes[0].type)}`"
         ></div>
         <div class="prizeBox" v-if="item.prizes.length > 0">
           <div class="prizeNum">
             {{ item.prizes[0].name }}
           </div>
         </div>
         <div class="signNIcon" v-if="i >= 6">
           <img src="@/assets/png/signNIcon.png" />
         </div>
       </div>
     </div>
   </div> -->

      <!-- 任务 -->
      <div class="taskList">
        <div class="title">做任务领取奖励</div>
        <div class="taskItem" v-for="item in dailyTask" :key="item.id">
          <div class="leftBox">
            <ImgDecypt :src="item.img" class="taskIcon" :key="item.img" />
            <div class="taskInfo">
              <div class="taskName">
                {{ item.title
                }}<span class="pointsTag">{{
                  item.detail.length > 0
                    ? item.detail[0]["prizes"][0]["name"]
                    : ""
                }}</span>
              </div>
              <div class="taskDesc">{{ item.desc }}</div>
            </div>
          </div>
          <div
            class="btn"
            @click.stop="todoD(item, 1)"
            v-if="item.detail && item.detail[0].status == 0"
          >
            去完成
          </div>
          <div
            class="btn receive"
            @click.stop="todo(item, 1)"
            v-else-if="item.detail && item.detail[0].status == 1"
          >
            待领取
          </div>
          <div
            class="btn received"
            v-else-if="item.detail && item.detail[0].status == 2"
          >
            已完成
          </div>
        </div>
        <div class="taskItem" v-for="item in onceTask" :key="item.id">
          <div class="leftBox">
            <ImgDecypt :src="item.img" class="taskIcon" :key="item.img" />
            <div class="taskInfo">
              <div class="taskName">
                {{ item.title }}
                <span class="pointsTag">{{
                  item.prizes.length > 0 ? item.prizes[0]["name"] : ""
                }}</span>
              </div>
              <div class="taskDesc">{{ item.desc }}</div>
            </div>
          </div>
          <div class="btn" @click.stop="todoD(item, 2)" v-if="item.status == 0">
            去完成
          </div>
          <div
            class="btn receive"
            @click.stop="todo(item, 2)"
            v-else-if="item.status == 1"
          >
            待领取
          </div>
          <div class="btn received" v-else-if="item.status == 2">已领取</div>
        </div>
        <!--                <div class="sizeBox"></div>-->
      </div>

      <!-- 积分兑换 -->
      <div class="exchangeTask" v-if="exchangeTaskList.length > 0">
        <div class="exchangeTitle">
          <div class="title">积分兑换</div>
          <!--                    <div class="Integral">总积分&nbsp;{{ userStore.walletInfo.point || 0 }}</div>-->
        </div>
        <div class="exchangeTaskList">
          <div
            class="exchangeTaskItem"
            v-for="item in exchangeTaskList"
            :key="item.id"
          >
            <div v-if="item.prize && item.prize.length > 0">
              <!--                             <img class="bg" src="@/assets/images/png/taskBg.png" /> -->
              <div class="TaskItemBg"></div>
              <ImgDecypt class="bg2" :src="item.prize[0].image" />
              <div class="tipBox">
                <div class="name">{{ item.prize[0].name }}</div>
                <div class="desc">{{ item.prize[0].desc }}</div>
              </div>
              <div class="btnBox">
                <!--                        <div class="title">{{item.prize[0].name}}</div>-->
                <div class="btnTitle">花费{{ item.point }}积分</div>
                <div class="btn" @click="exchange(item)">立即兑换</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sizeBox"></div>

      <!-- 收获地址 -->
      <van-overlay
        class="harvestAddressPop"
        :show="harvestAddressPop"
        @click="closeHarvestAddressPop"
      >
        <div class="wrapper" @click.stop>
          <div class="content">
            <div class="title">填写收货地址</div>
            <div class="divisionLine"></div>
            <div class="inputBox">
              <van-field class="name" v-model="name" placeholder="请输入姓名" />
              <van-field
                class="phone"
                v-model="phone"
                placeholder="请输入电话"
              />
              <van-field
                rows="4"
                type="textarea"
                class="address"
                v-model="address"
                placeholder="请输入地址"
              />
            </div>
            <div class="btnBox" @click="exchangeEntity">
              <div class="btn">立即提交</div>
            </div>
          </div>
        </div>
      </van-overlay>

      <!-- 签到提示框 -->
      <van-overlay :show="signPromptShow" v-if="signPromptShow">
        <div class="signPromptContent">
          <div class="content">
            <div class="titleBox">
              <div class="goldIconBig">
                <img src="@/assets/png/goldIconBig.png" />
              </div>
            </div>

            <div
              class="info"
              v-if="itemData['prizes'] && itemData['prizes'].length > 0"
            >
              <p class="title">第{{ signNum + 1 }}天连续签到奖励</p>
              <!--                            <p class="value">{{ itemData['prizes'][1]['name'] }}</p>-->
              <p class="value">{{ itemData["prizes"][0]["name"] }}</p>
            </div>
          </div>
          <div class="btnBox" @click="signPromptShow = false">
            <img src="@/assets/png/closeDialog.png" />
          </div>
        </div>
      </van-overlay>
    </div>
  </div>
</template>

<script>
import {
  queryExchangeTaskList,
  querySignList,
  taskExchangeEntity,
  taskSIgnGet,
  taskSIgnPost,
  toTaskClick,
  taskDo1,
  taskDo2,
  taskExchange,
} from "@/api/user";
import ImgDecypt from "@/components/ImgDecypt";
import { jumpAdv } from "@/utils/getConfig";
export default {
  name: "index",
  components: {
    ImgDecypt,
  },
  data() {
    return {
      dailyTask: [],
      onceTask: [],
      signList: [],
      exchangeTaskList: [],
      harvestAddressPop: false,
      signNum: 0,
      exchangeEntityId: "",
      name: "",
      phone: "",
      address: "",
      itemData: {},
      dayIs: false,
      signPromptShow: false,
      userInfo: {}, // 用户信息
      walletInfo: {}, // 钱包信息
    };
  },
  async mounted() {
    this.getTaskSIgnInfo();
    this.getTaskList();
    this.getExchangeTaskList();

    await this.$store.dispatch("user/getWallet");
    this.walletInfo = this.$store.getters.walletInfo;
  },
  methods: {
    scrollBottom() {
      const container = this.$refs.sign;
      if (container) {
        container.scrollTop = container.scrollHeight - container.clientHeight;
      }
    },
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    async getTaskSIgnInfo() {
      /**
       * 获取签到详情
       */
      let res = await this.$Api(taskSIgnGet);
      if (res.code == 200) {
        console.log(res, "------------");
        this.signList = res.data.list || [];
        this.signNum = res.data.value;

        let itemDataArr = res.data.list.filter((item) => {
          return item.status == 2;
        });

        if (itemDataArr.length > 0) {
          this.itemData = itemDataArr[0];
        } else {
          let hasDataArr = res.data.list.filter((item) => {
            return item.status == 3;
          });
          if (hasDataArr.length > 0) {
            this.itemData = hasDataArr[0];
            this.dayIs = true;
          }
        }
        console.log(this.itemData, "=--=-=-=-=-");

        await this.postGign();
      }
    },
    async exchange(item) {
      let req = {
        id: item.id,
      };
      let res = await this.$Api(taskExchange, req);
      if (res.code == 200) {
        if (res.data.id) {
          this.exchangeEntityId = res.data.id;
          this.harvestAddressPop = true;
        } else {
          this.$toast("兑换成功");
        }
      } else {
        this.$toast(res.tip);
      }
    },
    async postGign() {
      /**
       * 签到
       */
      if (this.dayIs) return;
      let res = await this.$Api(taskSIgnPost, {
        id: this.itemData.id,
      });
      if (res.code == 200) {
        console.log(res, "===============");
        this.dayIs = true;
        this.signPromptShow = true;
      }
    },
    async getExchangeTaskList() {
      /**
       * 任务兑换列表
       */
      let res = await this.$Api(queryExchangeTaskList, {
        pageNumber: 1,
        pageSize: 10,
      });
      if (res && res.code == 200) {
        this.exchangeTaskList = res.data.list || [];
      }
    },
    async getTaskList() {
      /**
       * 获取任务列表
       * @type {undefined|*}
       */
      let res = await this.$Api(querySignList);
      if (res && res.code == 200) {
        this.dailyTask = res.data.dailyTask || [];

        this.onceTask = res.data.onceTask || [];
      }
    },
    async todo(item, type) {
      this.$toast({
        message: "领取中...",
        forbidClick: true,
        duration: 0,
      });
      if (type == 1) {
        try {
          let req = {
            id: item.id,
            type: 1,
          };
          let res = await this.$Api(taskDo1, req);
          if (res.code == 200) {
            item.status = 2;
            this.$toast("领取成功");
            await this.getTaskList();
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          let req = {
            id: item.id,
            type: 2,
          };
          let res = await this.$Api(taskDo2, req);
          if (res.code == 200) {
            item.status = 2;
            this.$toast("领取成功");
            await this.getTaskList();
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    async todoD(item, type) {
      if (item.link) {
        jumpAdv(item);
      }
      if (type == 2) {
        if (item.type == 10) {
          try {
            let req = {
              id: item.id,
            };
            let res = await this.$Api(toTaskClick, req);
            if (res.code == 200) {
              await this.getExchangeTaskList();
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    closeHarvestAddressPop() {
      this.exchangeEntityId = "";
      this.harvestAddressPop = false;
    },
    async exchangeEntity() {
      /**
       * 提交收货地址
       */
      if (!this.name) {
        this.$toast("请填写姓名");
        return;
      }
      if (!this.phone) {
        this.$toast("请填写电话");
        return;
      }
      if (!this.address) {
        this.$toast("请填写地址");
        return;
      }
      let req = {
        id: this.exchangeEntityId,
        name: this.name,
        tel: this.phone,
        addr: this.address,
      };
      let res = await this.$Api(taskExchangeEntity, req);
      if (res.code == 200) {
        this.$toast("填写成功");
        this.closeHarvestAddressPop();
      }
    },
    getClass(type) {
      switch (type) {
        case 1:
          return "activeValue";
        case 2:
          return "gold";
        case 5:
          return "vipCard";
        case 6:
          return "vipDiscount";
        case 8:
          return "aiFreeTimes";
        case 9:
          return "aiFaceCoupon";
        case 10:
          return "goldVideoTicket";
        default:
          return "prizeImg";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sign {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background-image: url("../../../assets/png/signBg.png");
  background-repeat: no-repeat;
  background-size: 100% 219px;
  background-color: #f5f5f5;
  position: relative;

  .navBar {
    width: 100%;
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //border-bottom: 1px solid #e6e6e6;
    position: absolute;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 18px;
      color: #333333;
      padding-left: 20px;
    }

    .rightBtn {
      font-size: 14px;
      color: #666666;
      // width: 17px;
      // height: 17px;
    }
  }

  .content {
    .headInfo {
      width: 100%;
      //   height: 242px;
      //   background: #94d6da;
      padding: 57px 16px 0 16px;

      .headLeft {
        .infoBox {
          display: flex;
          align-items: center;

          .icon {
            width: 36px;
            height: 36px;
          }

          .amount {
            color: #333;
            font-size: 36px;
          }
        }

        .btnBox {
          color: #333;
          font-size: 16px;
          margin-top: 20px;
          margin-bottom: 24px;
          line-height: 20px;

          .icon {
            width: 20px;
            height: 20px;
            margin-left: 12px;
            vertical-align: top;
          }
        }
      }

      //   .headRight {
      //     position: absolute;
      //     right: 0;
      //     top: 52px;
      //     width: 160px;
      //     height: 160px;
      //     background: url("../../../assets/png/giftIcon.png"),
      //       50% / cover no-repeat;
      //     //.icon{
      //     //    width: 160px;
      //     //    height: 160px;
      //     //
      //     //    position: absolute;
      //     //    right: 0;
      //     //    top: 52px;
      //     //
      //     //    background: url('../../../assets/png/giftIcon.png'), lightgray 50% / cover no-repeat;
      //     //}
      //   }
    }

    .signInList {
      margin: 0 16px;
      padding: 0 3px 0 8px;
      border-radius: 11px;
      //width: 100%;
      box-sizing: border-box;
      position: absolute;
      top: 171px;
      background: #ffffff;
      height: 295px;

      .signInDay {
        font-size: 14px;
        color: #1a1a1a;
        margin-top: 16px;
        margin-bottom: 13px;
        //margin-left: 10px;

        span {
          font-size: 12px;
          color: #b0b0b0;
        }
      }

      .trophy {
        position: absolute;
        top: -104px;
        right: 7.3px;
        width: 120px;
        height: 129px;
        //background: url('@/assets/images/png/trophy.png') center no-repeat;
        background-size: cover;
      }

      .dayList {
        width: 100%;
        height: 200px;
        display: flex;
        flex-wrap: wrap;
        //display: grid;
        //grid-template-columns: repeat(4, 1fr);
        //grid-template-rows: repeat(2, 93px);
        //column-gap: 5px;

        // margin-top: 14.4px;
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        // flex-wrap: wrap;

        // grid-auto-flow: column;
        // display: grid;
        // grid-auto-flow: row;
        // grid-template-columns: auto;
        // grid-gap: 7px;
        .dayItem1 {
          // background-image: linear-gradient(to bottom, #ffe066, #ffe900) !important;
          color: #ffffff !important;
          background: #94d6da !important;

          .prizeNum {
            color: #ffffff !important;
          }
          .signMask {
            color: rgb(255, 255, 255) !important;
          }
        }

        .dayItem {
          width: 78px;
          height: 110px;
          background: #f5f5f5;
          border-radius: 10px;
          //padding: 12px 21px;
          font-size: 12px;
          color: #000000;
          text-align: center;
          margin-bottom: 10px;
          display: flex;
          //justify-content: space-between;
          flex-direction: column;
          align-items: center;
          margin-right: 5px;
          position: relative;
          // background-image: linear-gradient(to bottom, #f5f5f5, #d8d8d8);
          // overflow: hidden;
          // position: relative;

          .dayNum {
            margin-top: 12px;
          }

          .signNIcon {
            position: absolute;
            bottom: 0;
            right: 0;
          }

          .dayIcon {
            width: 19px;
            height: 14px;
            // margin: 4px 0 0 4px;
          }

          // .day1 {
          //   background: url('@/assets/images/png/signInDay1.png');
          //   background-size: 100% 100%;
          // }

          // .day2 {
          //   background: url('@/assets/images/png/signInDay2.png');
          //   background-size: 100% 100%;
          // }

          // .day3 {
          //   background: url('@/assets/images/png/signInDay3.png');
          //   background-size: 100% 100%;
          // }

          // .day4 {
          //   background: url('@/assets/images/png/signInDay4.png');
          //   background-size: 100% 100%;
          // }

          // .day5 {
          //   background: url('@/assets/images/png/signInDay5.png');
          //   background-size: 100% 100%;
          // }

          // .day6 {
          //   background: url('@/assets/images/png/signInDay6.png');
          //   background-size: 100% 100%;
          // }

          // .day7 {
          //   background: url('@/assets/images/png/signInDay7.png');
          //   background-size: 100% 100%;
          // }

          .prizeBox {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 8px;
            // margin: 11px 0 13px 0;

            .prizeNum {
              font-size: 12px;
              color: #a5a8a8;
            }
          }

          .day {
            width: 36px;
            height: 36px;
            margin-top: 8px;
            //margin-right: 5px;
          }
          .prizeImg {
            background: url("../../../assets/png/goldsign.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          .activeValue {
            background: url("../../../assets/png/goldsign.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          .gold {
            background: url("../../../assets/png/goldsign.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          .vipCard {
            width: 24px;
            height: 24px;
            background: url("../../../assets/png/vipCard.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          .vipDiscount {
            //background: url('@/assets/images/png/vipDiscount.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          .aiFreeTimes {
            background: url("../../../assets/png/goldsign.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          .aiFaceCoupon {
            background: url("../../../assets/png/goldsign.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          .goldVideoTicket {
            background: url("../../../assets/png/goldsign.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }

          .signMask {
            // position: absolute;
            // bottom: 0;
            // left: 0;
            // background: rgba(36, 41, 52, 0.51);
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgb(128, 128, 128);
          }
        }
        .bigDayItem {
          width: 140px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          grid-column: span 2;
          //background-image: url('@/assets/images/png/sundayGift.png');
          background-repeat: no-repeat;
          background-size: 60px 60px;
          background-position: 100px 15px;
          padding-left: 21px;
        }
      }
    }

    .exchangeTask {
      margin-top: 24px;
      padding: 0 16px;
      .exchangeTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 16px;
          color: #333;
          margin-bottom: 12px;
        }
        .Integral {
          font-size: 16px;
          margin-bottom: 10px;
        }
      }

      .exchangeTaskList {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 7px;
        grid-column-gap: 7px;

        .exchangeTaskItem {
          position: relative;
          //background: #FFFFFF;
          border-radius: 6px;

          .bg {
            //width: 160px;
            height: 76px;
            border-radius: 6px 6px 0 0;
          }
          .TaskItemBg {
            height: 76px;
            border-radius: 6px 6px 0 0;
            border: solid #ffffff 1px;
          }
          .bg2 {
            position: absolute;
            top: 10px;
            right: 12px;
            width: 60px;
            height: 60px;
          }

          :deep(.animationImg) {
            border-radius: 6px 6px 0 0;
          }

          .title {
            font-size: 12px;
            color: rgb(51, 51, 51);
          }

          .tipBox {
            position: absolute;
            top: 15px;
            left: 12px;
            .name {
              font-size: 16px;
              color: #333;
              font-weight: bold;
            }
            .desc {
              font-size: 10px;
              color: #999;
            }
          }

          .btnBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 9px 12px;
            background: #ffffff;

            .btnTitle {
              font-size: 12px;
              color: #000;
              font-weight: normal;
            }

            .btn {
              width: 50px;
              height: 20px;
              border-radius: 10.5px;
              font-size: 10px;
              color: #616161;
              // background: linear-gradient(to right, rgb(255, 170, 128), rgb(255, 204, 178));
              background: #fbd13d;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }

    .taskList {
      padding: 16px 16px;
      background: #ffffff;
      border-radius: 11px;
      margin: 0 16px 0 16px;

      .title {
        color: #1a1a1a;
        font-size: 14px;
        margin-bottom: 12px;
      }
      .taskItem {
        margin-bottom: 10px;
        background: #fafafa;
        border-radius: 8px;
        padding-right: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .leftBox {
          display: flex;
          align-items: center;
          flex: 1;
          padding: 11px 12px;
          .taskIcon {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            overflow: hidden;
            transform: translateZ(0px);
          }
          .taskInfo {
            flex: 1;
            margin-left: 12px;
            .taskName {
              font-size: 14px;
              line-height: 16px;
              color: #3a3639;
              font-weight: bold;

              .pointsTag {
                color: #f54570;
                font-size: 12px;
              }
            }
            .taskDesc {
              font-size: 12px;
              color: rgb(153, 153, 153);
              margin-top: 5px;
              line-height: 16px;
            }
          }
        }
        .btn {
          height: 28px;
          width: 60px;
          // background: linear-gradient(to right, rgb(255, 170, 128), rgb(255, 204, 178));
          background: #fbd13d;
          border-radius: 36px;
          text-align: center;
          line-height: 27px;
          font-size: 12px;
          //   font-weight: 600;
          //   color: #616161;
          color: rgba(61, 61, 61, 0.9);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .receive {
          color: #ffffff;
          background: #73cd50;
        }
        .received {
          opacity: 0.3;
          //   color: #ffffff;
          //   background: #94d6da;
        }
      }
    }
  }

  .harvestAddressPop {
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .content {
        width: 300px;
        height: 422px;
        //background: linear-gradient(to bottom, rgb(255, 238, 228), rgb(255, 255, 255));
        background: linear-gradient(180deg, #e5feff 0%, #fff 100%);
        border-radius: 12px;

        .title {
          font-size: 18px;
          color: rgb(51, 51, 51);
          font-weight: bold;
          padding: 25px 0 14px 0;
          text-align: center;
        }

        .divisionLine {
          height: 1px;
          background: linear-gradient(
            90deg,
            rgba(230, 230, 230, 0) 0%,
            #e5e5e5 47.92%,
            rgba(230, 230, 230, 0) 100%
          );
          //background: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(0, 0, 0), rgba(0, 0, 0, 0));
        }

        .inputBox {
          padding: 0 22px;

          .name {
            margin-top: 21px;
          }

          .phone {
            margin-top: 24px;
          }

          .address {
            margin-top: 24px;
          }

          :deep(.van-cell) {
            background: rgba(0, 0, 0, 0.05);
            border-radius: 4px;
          }
        }

        .btnBox {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 24px;

          .btn {
            width: 256px;
            height: 38px;
            // background: linear-gradient(to right, rgb(255, 170, 128), rgb(255, 204, 178));
            background: #94d6da;
            font-size: 14px;
            color: #333333;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 19px;
          }
        }
      }
    }
  }

  .sizeBox {
    width: 100%;
    height: 100px;
  }

  .signPromptContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .content {
      width: 300px;
      //height: 249px;
      background: rgb(255, 255, 255);
      border-radius: 11px;
      //padding: 20px 0;
      padding-bottom: 20px;

      .titleBox {
        //font-size: 20px;
        //color: rgb(51, 51, 51);
        //font-weight: bold;
        //text-align: center;
        height: 130px;
        background-image: url("../../../assets/png/signPromptBg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        padding-top: 28px;

        .goldIconBig {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          background-image: linear-gradient(
            rgb(255, 226, 229),
            rgb(255, 244, 245),
            rgb(253, 242, 244)
          );

          img {
            width: 62px;
            height: 62px;
          }
        }
      }

      .info {
        font-size: 16px;
        color: rgb(51, 51, 51);
        text-align: center;
        margin-top: 20px;

        .value {
          margin-top: 18px;
          font-size: 14px;
          color: rgb(255, 13, 44);
        }
      }
    }

    .btnBox {
      margin-top: 24px;

      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}
</style>
